import React, { useState } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import AboutJSON from "../content/about.json"
import "animate.css/animate.min.css";
import "../styles/bootstrap.scss"
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

export default function LeadershipTeam() {
    const [cardInfo, setCardInfo] = useState({})
    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);

    const directorImage = (name, size) => {
        let style = {
            width: size ?? "150px",
            height: size ?? "150px",
            objectFit: "cover",
            borderRadius: '50%',
            border: '3px solid #27476E',
            mixBlindMode: "multiple",
            background: "#838483",
            margin: '20px auto',
        }

        switch(name) {
            case "art":
                return <img style={style} src='leaders/art.jpeg' alt={name}/>
            case "joe":
                return <img style={style} src='leaders/joe.jpeg' alt={name}/>
            case "david":
                return <img style={style} src='leaders/david.png' alt={name}/>
            case "sloane":
                return <img style={style} src='leaders/sloane.png' alt={name}/>
            case "hart":
                return <img style={style} src='leaders/hart.jpg' alt={name}/>
            case "frank":
                return <img style={style} src='leaders/frank.jpeg' alt={name}/>
            default:
                break;
        }
    }

    const cardOnClick = bio => {
        onOpenModal(true)
        setCardInfo(bio)
    }

  return (
    <Layout>
        <Seo title="Leadership Team" />
        <img className='parallax team' src="/gear.jpg" alt="Parallax" />
        <div className="banner-cover" />
        <div className="banner animate__animated fadeInDownSmall">
            <h1 className="display-3"><b>{AboutJSON.leadership.title}</b></h1>
        </div>
        <Container fluid className="bg-light pb-4" style={{padding: '5% 0 0', boxShadow: 'inset 0 5px 5px  #bebebe'}}>
            <Container className="mt-0">
                <h6>{AboutJSON.leadership.subtitle.toUpperCase()}</h6>
                {/* <p>One sentence to describe this leadership team.</p> */}
                <div className='leadership-teams'>
                    {AboutJSON.leadership.directors.map((director, key) => (
                        <div aria-hidden="true" key={key} className={'director ' + (director.name === cardInfo.name ? 'action' : "")} onClick={() => cardOnClick(director)}>
                            {directorImage(director.shortName)}
                            <h5 style={{color: "#242943"}}>{director.name}</h5>
                            <h6>{director.title}</h6>
                            <a href={director.linkedIn} target="_blank" rel="noopener noreferrer" style={{width: 25, display: 'flex', margin: '30px auto 0', justifyContent: 'center'}}>
                                <FontAwesomeIcon
                                    icon={faLinkedin} 
                                    style={{
                                        fontSize: 25,
                                        color: "#27476E"
                                    }}
                                />
                            </a>
                        </div>
                    ))}
                </div>

            </Container>
        </Container>
        <Modal 
            open={open}
            onClose={onCloseModal}
            center
            classNames={{
                modal: 'cardModal',
            }}
        >
            <div className='px-3 d-flex h-100'>
                <div style={{textAlign: 'center'}}>
                    {directorImage(cardInfo.shortName, '200px')}
                    <a href={cardInfo.linkedIn} target="_blank" rel="noopener noreferrer" style={{width: 25, display: 'flex', margin: '0 auto', justifyContent: 'center'}}>
                        <FontAwesomeIcon
                            icon={faLinkedin} 
                            style={{
                                fontSize: 25,
                                color: "#27476E"
                            }}
                        />
                    </a>
                    {/* <h5 className='mt-5'><i>"An inspiring quote from this director?"</i></h5> */}
                </div>
                <div className='ml-5 mt-3'>
                    <h2 className='pl-3 py-1' style={{color: 'white', background: "linear-gradient(-90deg, white 15%, #27476E 45%, #242943 80%)"}}>{cardInfo.name}</h2>
                    <h6 className='mb-4' style={{color: '#242943'}}>{cardInfo.title}</h6>
                    <p
                        style={{
                            maxHeight: '55vh',
                            overflowY: 'auto',
                            whiteSpace: "pre-line"
                        }}
                    >
                        {cardInfo.bio}
                    </p>
                </div>
            </div>
        </Modal>
    </Layout>
  )
}
